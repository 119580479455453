<template>
  <div>
    <v-form v-model="isFormValid" ref="form" lazy-validation>
      <v-row>
        <v-col v-if="error" cols="12">
          <v-alert type="error" dense>{{ error }}</v-alert>
        </v-col>
        <v-col v-if="success" cols="12">
          <v-alert type="success" color="success darken-3"
            >Tu contraseña se ha modificado correctamente, ahora puedes volver a
            iniciar sesión.</v-alert
          >
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="newPassword"
            label="Ingresa tu nueva contraseña"
            color="secondary"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :rules="[$rules.required()]"
            outlined
            dense
            @click:append="showPassword = !showPassword"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="newPasswordConfirmation"
            label="Confirma la contraseña"
            color="secondary"
            type="password"
            :rules="[
              $rules.required(),
              $rules.equals(newPassword, 'Las contraseñas no coinciden'),
            ]"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col v-if="!success" cols="12">
          <v-btn
            color="secondary"
            :disabled="!isFormValid"
            :loading="loading"
            block
            @click="send"
            >Actualizar contraseña</v-btn
          >
        </v-col>
        <v-col v-else cols="12">
          <v-btn color="success darken-3" block @click="$router.push('/login')"
            >Volver a iniciar sesión</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import serverRequestMixin from "../../mixins/serverRequest.mixin";

export default {
  mixins: [serverRequestMixin],

  props: {
    recoverPasswordId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      error: "",
      success: false,
      isFormValid: false,
      showPassword: false,
      newPassword: "",
      newPasswordConfirmation: "",
    };
  },

  methods: {
    async send() {
      await this.$refs.form.validate();
      if (!this.isFormValid) return;
      else await this.sendNewPassword();
    },

    async sendNewPassword() {
      this.loading = true;
      this.error = "";
      this.success = false;

      try {
        await this.putRequest(
          "/users/user/password",
          {
            newPassword: this.newPassword,
          },
          false,
          { token: this.recoverPasswordId, recover: true }
        );
        this.success = true;
      } catch (error) {
        if (error.data && error.status != 500) this.error = error.data.message;
        else {
          console.error(error);
          this.error =
            "Error actualizando la nueva contraseña, contacta con soporte.";
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
